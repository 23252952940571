
// _base.scss imports _custom.scss (variables and mixins) and other partials.
@import "base";

/*
 * @file
 * global.styles
 *
 * Styles you add here will load for all device sizes, they are "global", as
 * opposed to "responsive" styles, which only load for a given breakpoint (media
 * query) that you set in theme settings. See the README for more details.
 *
 *
 * How to use this file:
 * --------------------
 *
 * There are many empty selectors and some basic styles to act as a guide.
 * Nothing is set in stone and you can change anything - even delete all of it
 * and start with your own clean slate.
 *
 * To FORCE PRINT all selectors you can search and replace a single { (opening
 * curly brace) and insert an empty comment. This is very useful in conjuction
 * with FireSass: https://addons.mozilla.org/en-US/firefox/addon/firesass-for-firebug/
 *
 * See global.base also which includes normalize.css and some additional
 * helper classes and base styles.
 */

/* =============================================================================
 *   Base
 * ========================================================================== */

/*
 * To preserve theme settings never apply font properties to the HTML element.
 * This is critically important if you are using the Responsive JavaScript
 * feature as this relies on being able to set a pseudo font family on the HTML
 * element. If you need to set default font properties of any kind use the BODY
 * element as these can be overridden using font theme settings.
 */

html {
  background: $page;
}

body {
  color: #252525;
  font-family: $sans-serif-arial-helvetica; // Set from _custom.scss font variables
  font-size: 75%;
  background: url("/sites/all/themes/gobzor/css/images/body_bg.jpg") repeat scroll 0 0 rgba(0, 0, 0, 0);
}


/* =============================================================================
 *   HTML Elements
 * ========================================================================== */

h1 {}


h2 {}


h3 {}


h4 {}


h5 {}


h6 {}


p {}


b,
strong {}


i,
em {}


dfn {}


sup {}


sub {}


del {}


ins {}


blockquote {}


cite {}


q {}


address {}


ul {}


ol {}


li {}


dl {}


dd {}


dt {}


abbr {}


acronym {}


pre,
code,
tt,
samp,
kbd,
var {
  font-family: $mono;
}


/* =============================================================================
 *   Wrappers
 * ========================================================================== */

/*
 * Outer wrappers:
 * - #page-wrapper: body > #page-wrapper
 * - #page: body > #page-wrapper > #page
 * - .container: body > #page-wrapper > #page.container
 *
 * The .container class holds the page width value from theme settings. You can
 * style the .container class but do not add width such as border, padding,
 * margins etc.
 */

#page-wrapper {

}


#page  {
  padding-bottom: 15px;
  &.container {}
}


/*
 * Wraps all header elements - branding and .region-header
 */
#header {}


/*
 * Wraps the sidebars the content column
 */
#columns {
  background: url("/sites/all/themes/gobzor/css/images/wrapper_bg.jpg") repeat scroll 0 0 rgba(0, 0, 0, 0);
  border-radius: 3px;
  padding-top: 20px;
}


/*
 * Main content column wrapper
 */
#content-column {}


/*
 * Wraps the main-content-header, the content region and feed-icons. Use this
 * wrapper to style the entire main content column
 */
#main-content {}


/*
 * Wraps the content region, avoid applying styles to this wrapper, its used
 * mainly for position and invisible gutters and can be problematic to style
 */
#content {
      background-color: white;
      border: 1px solid #dfdbcb;
      border-radius: 4px;
      box-shadow: 0 0 4px #e2dac8;
      margin-bottom: 20px;
      padding: 10px 20px;
}


/*
 * Footer wrapper
 */
#footer {}


/*
 * Panels may need additional margin wrangling when in the $content region
 */
#content .panel-display {}


/*
 * Full Width Wrappers
 * These are used in the optional page--full-width-wrappers.tpl.php template.
 * All have internal .container classes. In the full width wrapper template the
 * .container classes set the width or max-width - you can style this but never
 * add any width/margin/padding/borders etc to .container
 */

/*
 * Wraps the leaderboard
 */
#leaderboard-wrapper {
  .container {}
}


/*
 * Wraps the header
 */
#header-wrapper {
  /* Debug styles, is this working */
  background: $debug;
  .container {}
}


/*
 * Wraps the nav elements
 */
#nav-wrapper {
  .container {}
}


/*
 * Wraps breadcrumb
 */
#breadcrumb-wrapper {
  .container {}
}


/*
 * Wraps messages and help
 */
#messages-help-wrapper {
  .container {}
}


/*
 * Wraps the secondary content/preface region
 */
#secondary-content-wrapper {
  .container {}
}


/*
 * Wraps the main content column
 */
#content-wrapper {
  .container {}
}


/*
 * Wraps the tertiary content/postfix region
 */
#tertiary-content-wrapper {
  .container {}
}


/*
 * Footer wrapper
 */
#footer-wrapper {
  /* Debug styles, is this working? */
  background: $debug;

  .container {}
}


/* =============================================================================
 *   Branding
 * ========================================================================== */

/*
 * Wraps all the branding elements, logo, name and slogan
 */
#branding {}


/*
 * Logo
 */
#logo {
  padding: 0 0 7px 0;

  img {
    vertical-align: bottom;
  }
}


/*
 * Wrapper for the site name and slogan
 */
#name-and-slogan {}


/*
 * Site name (h1)
 */
#site-name {
  margin: 0;

  a {

    &:link,
    &:visited {
      text-decoration: none;
    }

    &:hover,
    &:focus {
      text-decoration: underline;
    }
  }
}


/*
 * Site slogan (h2)
 */
#site-slogan {
  margin: 0;
}


/* =============================================================================
 *   Page content header
 * ========================================================================== */

/*
 * Main content header wraps the page title, tabs and actions links
 */
#main-content-header {}


/*
 * The main page title (h1)
 */
#page-title {
  margin: 0;
}


/* =============================================================================
 *   Misc Global Styles
 * ========================================================================== */

/*
 * Feed icons
 */
.feed-icon {}


/*
 * Aggregator feed source
 */
#aggregator .feed-source .feed-icon {
  display: inline;
  float: none;
  margin-right: 10px;
}


.feed-details dt,
.feed-details dd {
  display: inline;
  margin: 0;
}


/*
 * Generic styles for the more link
 */
.more-link {}


/*
 * Generic styles for links. See the ul.links declaration in node and comment stylesheets
 */
ul.links {
  margin: 0;
  padding: 0;

  &.inline {
    display: block;
  }

  li {
    display: inline;
    list-style: none;
    padding: 0 10px 0 0;
  }
}


/*
 * Search results are an ordered list so reset the margin
 */
.search-results {
  margin: 0;
  padding: 0;
}


/*
 * Attribution message and link
 */
.attribution {
  display: block;
  opacity: 0.65;
  padding: 1em 0;
  text-align: center;

  a {
    text-decoration: none;
    color: inherit;

    &:hover,
    &:focus {
      text-decoration: underline;
    }
  }
}


/* =============================================================================
 *   Regions
 * ========================================================================== */

/*
 * Standard region wrapper, don't add width to any regions, you will bork the layout - no margin, padding or borders etc
 */
.region {}


/*
 * Regions have an inner div - perfect for adding margin, padding or borders
 */
.region-inner {}


/*
 * Regions can be nested, such as when using Panels
 */
.region-inner .region-inner {}


/*
 * Header region, embedded in the #header
 */
.region-header {}


/*
 * Generally used to output Drupals help block, if the help module is enabled
 */
.region-help {}


/*
 * Secondary content
 */
.region-secondary-content {}


/*
 * Sits above the main content header, like a content-top region
 */
.region-highlighted {}


/*
 * Sits below the main content, like a content-bottom region
 */
.region-content-aside {}


/*
 * Sidebars - targets both
 */
.sidebar {}


/*
 * First sidebar
 */
.region-sidebar-first {
  .region-inner {
    margin-left: 10px;
    margin-right: 10px;
  }
}


/*
 * Second sidebar
 */
.region-sidebar-second {}


/*
 * Tertiary content
 */
.region-tertiary-content {}


/*
 * Footer region
 */
.region-footer {}


/* =============================================================================
 *   Links
 * ========================================================================== */

a {
  color: #3785d7;

  &:link,
  &:visited {}

  &:active,
  &.active {}

  &:hover,
  &:focus {

  }
}


/* =============================================================================
 *   Primary, Secondary and Menu Bar region menus
 * ========================================================================== */

.nav {
  clear: both;
  margin: 0 0 17px 0;

  ul,
  ul.menu {
    margin: 0;
    padding: 0;
  }

  li,
  ul.menu li {
    display: inline;
    float: left;
    list-style: none;
    margin: 0;
    padding: 0;
  }

  li a,
  ul.menu li a {
    display: block;
    white-space: nowrap;
    padding: 0 10px;

    &:visited {}

    &:hover,
    &:focus {}
  }

  .block {
    margin-bottom: 0;
  }
}


/* =============================================================================
 *   Superfish
 * ========================================================================== */

ul.sf-menu {
  margin-bottom: 0;

  a {
    border-left: 0;
    border-top: 0;
    padding: 0 5px;
//    text-decoration: none;
    height: 1.5em;
//    line-height: 2.5em;

    &:link,
    &:visited {}
  }

  li {
    &:hover,
    &.sfHover {
      outline: 0;
    }
  }

  a {
    &:focus,
    &:hover,
    &:active {
      outline: 0;
    }
  }
}


/*
 * Superfish blocks
 */
.block-superfish {
  margin-right: 0;
  margin-left: 0;
  .block-inner .content {}

  ul {
    margin: 0 !important;

    ul {
      ul {
        ul {}
      }
    }
  }

  li {
    margin: 0 !important;
  }
}


/*
 * Vertical style
 */
.sf-vertical {
  width: 100%;

  li {
    width: 100%;

    &.last {}

    &:hover ul,
    &.sfHover ul {
      left: 100%;
      top: 0;
      margin: 0;
      padding: 0;
    }

    a {
      padding: 0 10px;
    }
  }
}


/*
 * Navbar style
 */
.sf-navbar {
  padding-bottom: 0 !important;
}


/*
 * Sensible padding for the default style
 */
.sf-menu.sf-style-default a {
  padding: 0 10px;
}


/* =============================================================================
 *   Menu Toggles
 * ========================================================================== */
// .at-mt is a page class
.at-mt {
  .at-menu-toggle,
  .at-menu-toggle ul,
  .at-menu-toggle ul.menu {
    margin-top: 0;
    margin-bottom: 0;
    padding: 0;
  }

  .at-menu-toggle-button {
    margin: 0;
  }

  .at-menu-toggle ul a {
    padding: 0 10px;
    white-space: nowrap;
  }
}


/* =============================================================================
 *   Menus, usually blocks
 * ========================================================================== */

ul.menu {
  padding-left: 15px;

  ul {
    padding-left: 15px;

    ul {
      ul {}
    }
  }

  li {
    margin: 0;

    &.collapsed,
    &.expanded,
    &.leaf {}

    a {

      &:link,
      &:visited {}

      &:active,
      &.active {}

      &:hover,
      &:focus {}
    }

    &.active a,
    &.active-trail a {}

    &.first,
    &.last {}
  }
}


/* If li.content exists it's a problem, so reset the padding */
.block .menu li.content {
  padding: 0;
}


/* =============================================================================
 *   Book navigation menu
 * ========================================================================== */

.book-navigation {

  .page-links {}

  .page-previous {}

  .page-next {}

  .page-up {
    /* Prevent text wrapping to a new line, assumes English "up" is used (two characters) */
    min-width: 2em;
    white-space: nowrap;
  }

  .menu {
    margin-left: 0;
  }
}


/* =============================================================================
 *   Breadcrumbs
 * ========================================================================== */

#breadcrumb {
  margin: 10px 0;

  /* If the label is set to show in theme settings the label class is added */
  .breadcrumb-label {
    font-size: 1em;
    display: inline;
    padding-right: 10px;

    &:after {
      content: ":";
    }
  }

  ol {
    margin: 0;
    padding: 0;
  }

  .with-breadcrumb-label ol {
    display: inline;
  }

  li {
    list-style: none;
    display: inline;
  }

  li.crumb-first {}

  li.crumb-last {}

  a {

    &:link,
    &:visited {}

    &:active,
    &.active {}

    &:hover,
    &:focus {}
  }
}

.breadcrumb {
  background-color: white;
  border: 1px solid #dfdbcb;
  border-radius: 3px;
  box-shadow: 0 0 4px #e2dac8;
  margin: 0 10px 10px 10px;
  padding: 5px 10px;
}

/* =============================================================================
 *   Pagers
 * ========================================================================== */

ul.pager {
  clear: both;
  margin: 0;
  text-align: center;
}


.item-list ul.pager li {
  margin: 0;
}


ul.pager li {
  background-image: none;
  display: inline;
  list-style-type: none;
  padding: .5em;

  &.pager-current {
    font-weight: 700;
  }
}


.block ul.pager li {
  margin: 0;
}


/*
 * Theme the various states of pager links
 */
ul.pager li {

  a {

    &:link,
    &:visited {}

    &:active,
    &.active {}

    &:hover,
    &:focus {}
  }

  &.pager-item {}
  &.first {}
  &.last {}
  &.pager-current {}
  &.pager-first {}
  &.pager-previous {}
  &.pager-next {}
  &.pager-last {}
}


/* =============================================================================
 *   Skip Navigation
 * ========================================================================== */

#skip-link {
  left: 50%;
  margin-left: -6.5em;
  margin-top: 0;
  padding: 0 0.5em;
  position: absolute;
  width: 12em;
  z-index: 50;

  a {
    background: #444;
    background: rgba(0, 0, 0, 0.6);
    color: $page;
    display: block;
    line-height: 2;
    padding: 0;
    text-align: center;
    text-decoration: none;

    &:link,
    &:visited {
      background: #444;
      background: rgba(0, 0, 0, 0.6);
      color: $page;
      display: block;
      line-height: 2;
      padding: 0;
      text-align: center;
      text-decoration: none;
    }
    &:hover,
    &:focus,
    &:active {
      outline: 0;
    }
  }
}


/* =============================================================================
 *   Tabs (local tasks)
 * ========================================================================== */

#tasks {
  margin-bottom: 15px;
}


ul.primary {
  border-bottom-color: $border;
  margin: 20px 0;
  padding: 0 0 0 5px;

  li {
    display: block;
    float: left;
    margin: 0 1px -1px;

    a {
      background-color: $ultralight;
      border-color: $border;
      margin-right: 1px;
      padding: 0 10px;
      display: block;
      float: left;
      height: 1.5em;
      line-height: 1.5em;

      &:hover,
      &:focus {
        background-color: $light;
        border-color: $border;
      }
    }
  }

  li.active {
    a,
    a:hover,
    a:focus {
      background-color: $page;
      border-bottom-color: $page;
    }
  }
}


ul.secondary {
  border-bottom: 1px solid $border;
  margin: 1em 0 0;
  padding: 0 .3em 1em;

  li {
    border-right: 0;
    list-style: none;
    padding: 0 10px 0 0;

    a {
      &:hover,
      &.active {
        border-bottom: none;
        text-decoration: underline;
      }
    }
  }
}


/* =============================================================================
 *   Action links
 * ========================================================================== */

ul.action-links {
  margin: 20px 0 0;
  list-style: none;

  li {}
}


/* =============================================================================
 *  Field Styling
 * ========================================================================== */

/*
 * Wrapper for any field
 */
.field {}


/*
 * Above and inline classes are on the field wrapper
 */
.field-label-above {}


/*
 * When labels are set to inline in field display settings the clearfix class is automatically added
 */
.field-label-inline {

  /* Remove the margin on inline field lables to preserve vertical formatting */
  .field-label {
    margin: 0;
  }
}

/*
 * Labels are h2 in Adaptivetheme. Use a strong selector to mitigate unwanted ineritance issues
 */
.field-label {
  font-size: 1em;
  font-weight: 700;
  font-family: inherit;
  line-height: inherit;
  margin-bottom: 0;
}


/*
 * Field types (Core)
 */

/*
 * Image fields use the <figure> and <figcaption> elements from HTML5
 */
.field-type-image {

  .caption {}

  .full-caption {}

  .teaser-caption {}
}


/*
 * Taxonomy
 */
.field-type-taxonomy-term-reference {

  /* The same bottom margin as p, blockquote, ul, ol and dl */
  margin-bottom: 1.5em;

  &.field-label-inline .field-items {
    margin: 0;
    padding: 0;
  }

  &.field-label-inline .field-item {
    display: inline;
    list-style: none;
    padding: 0 10px 0 0;
  }
}


/*
 * Text
 */
.field-type-text {}


/*
 * Long text
 */
.field-type-text-long {}


/*
 * Text with summary
 */
.field-type-text-with-summary {}


/*
 * File
 */
.field-type-file {}


/*
 * Number/Integer
 */
.field-type-number-integer {}


/*
 * Decimal
 */
.field-type-number-decimal {}


/*
 * Number float
 */
.field-type-number-float {}


/*
 * List
 */
.field-type-list-text {}


/*
 * List boolean
 */
.field-type-list-boolean {}


/*
 * List integer
 */
.field-type-list-integer {}


/*
 * List float
 */
.field-type-list-float {}


/*
 * Field types (Contrib)
 */
.field-type-datetime {}

.field-type-node-reference {}

.field-type-user-reference {}


/*
 * Named fields
 */
.field-name-body {}

.field-name-field-image {}

.field-name-field-tags {}


/*
 * Underscores in field name are replaced with dashes
 */
.field-name-field-FIELDNAME {}


/*
 * Image Alignment Theme Settings - included here so you can easily override
 */

/*
 * Float none setting
 */
.ia-n .field-type-image,
.iat-n .field-type-image {}


/*
 * Float left setting
 */
.ia-l .field-type-image figure,
.iat-l .field-type-image figure {
  margin: 5px 20px 15px 0;
}


/*
 * Centered setting
 */
.ia-c .field-type-image figure,
.iat-c .field-type-image figure {
  margin: 5px auto 15px;
}


/*
 * Float right setting
 */
.ia-r .field-type-image figure,
.iat-r .field-type-image figure {
  margin: 5px 0 15px 20px;
}



/* =============================================================================
 *   Block Styling
 * ========================================================================== */

/*
 * Main wrapper for most blocks, block_system_main does not have it
 */
.block {
  margin-bottom: 20px;
}


/*
 * Inner wrapper for most blocks, good for margin, padding and borders, block_system_main does not have it
 */
.block-inner {}


/*
 * The first block in the region
 */
.block.first {}


/*
 * The last block in the region
 */
.block.last {}


/*
 * Zebra striping for each block in the region
 */
.block.odd {}


/*
 * Zebra striping for each block in the region
 */
.block.even {}


/*
 * Block title
 */
.block-title {
  margin: 0;
}


/*
 * Block content wrapper
 */
.block-content {}


/*
 * Match item list and block menu margin and padding
 */
.block-content {

  ul,
  ol {
    padding: 0 0 0 15px;
  }

  li {
    margin: 0;
    padding: 0;
  }
}


/*
 * Block for the latest news items in the first category
 */
#block-aggregator-category-1 {}


/*
 * Block for the latest news items in the first feed
 */
#block-aggregator-feed-1 {}


/*
 * First block created with "Add block" link
 */
#block-block-1 {}


/*
 * "Recent blog posts" block
 */
#block-blog-recent {}


/*
 * "Book navigation" block for the current book's table of contents
 */
#block-book-navigation {}


/*
 * "Recent comments" block
 */
#block-comment-recent {}


/*
 * "Active forum topics" block
 */
#block-forum-active {}


/*
 * "New forum topics" block
 */
#block-forum-new {}


/*
 * Language switcher block
 */
#block-locale-language {}


/*
 * Custom menu block
 */
#block-menu-menu-NAME {}


/*
 * "Recent content" block
 */
#block-node-recent {}


/*
 * "Syndicate" block for primary RSS feed; see also page.css's .feed-icon
 */
#block-node-syndicate {}


/*
 * "Most recent poll" block
 */
#block-poll-recent {}


/*
 * "Author information" block for the profile of the page's author
 */
#block-profile-author-information {}


/*
 * "Search form" block
 */
#block-search-form {}


/*
 * "Shortcuts" block
 */
#block-shortcut-shortcuts {}


/*
 * "Popular content" block
 */
#block-statistics-popular {}


/*
 * "Main menu" block
 */
#block-system-main-menu {}


/*
 * "Management" block for Drupal management menu
 */
#block-system-management {}


/*
 * "Navigation" block for Drupal navigation menu
 */
#block-system-navigation {}


/*
 * "User menu" block for Drupal user menu
 */
#block-system-user-menu {}


/*
 * "System help" block
 */
#block-system-help {}


/*
 * "Main page content" block
 */
#block-system-main {}


/*
 * "Powered by Drupal" block
 */
#block-system-powered-by {}


/*
 * "User login form" block
 */
#block-user-login {}


/*
 * "Who's new" block for a list of the newest users
 */
#block-user-new {}


/*
 * "Who's online" block for a list of the online users
 */
#block-user-online {}


/* =============================================================================
 *   Node Styling
 * ========================================================================== */

.node {

  &.node-promoted {}

  &.node-sticky {}

  &.node-by-viewer {}

  &.node-teaser {}

  &.node-full {}

  &.odd {}

  &.even {}

  .node-title {
    margin: 0;
  }

  .user-picture {}

  .submitted {

    .username {}

    time {}
  }

  .node-content {}

  ul.links {

    li {}
    li a {}

    li.node-read-more a {}
    li.comment-add a {}
    li.comment-comments a {}
    li.comment-new-comments a {}
    li.blog-sernames-blog a {}
    li.print-html a {}
    li.print-email a {}
    li.print-pdf a {}
  }
}


.preview .node {}


/*
 * All nodes are given a node-FOO class that describes the type of content that
 * it is. If you create a new content type called "my-custom-type", it will
 * receive a "node-my-custom-type" class.
 */
.node-page {}

.node-article {}

.node-book {}

.node-forum {}

.node-poll {}


/* =============================================================================
 *   Comment Styling - Comments, comment wrapper, comment form
 * ========================================================================== */

/*
 * Wrapper for the list of comments and its titles
 */
#comments {
  margin: 1.5em 0;

  h2 {
    &.comment-title {
      margin: 0;
    }

    &.comment-form {
      margin: 0;
    }
  }
}


/*
 * Wrapper for a single comment
 */
.comment {
  margin-bottom: 20px;

  &.first {}

  &.last {}

  &.odd {}

  &.even {}

  .user-picture {}

  .submitted {

    p {}

    .username {}

    time {}
  }

  .user-signature {}

  ul.links {}
}


/*
 * Comment title
 */
.comment-title {
  margin: 0;
}


/*
 * Comment states
 */
.comment-new {}

.comment-by-anonymous {}

.comment-by-node-author {}

.comment-by-viewer {}

.comment-title-hidden {}

.comment-with-picture {}

.comment-with-signature {}


/*
 * Preview of the comment before submitting new or updated comment
 */
.comment-preview {}


/*
 * "New" marker for comments that are new for the current user
 */
.new {
  color: $highlight;
}


/*
 * Nested comments are indented
 */
.indented {
  margin-left: 40px;
}


/* =============================================================================
 *   Forms
 * ========================================================================== */

/*
 * Wrapper for a form element (or group of form elements) and its label
 */
.form-item {

  input.error,
  textarea.error,
  select.error {
    border: 1px solid $highlight;
  }

  label {
    font-weight: 700;
  }

  label.option {
  }

  .description {
    font-size: 0.85em;
  }

  &.form-checkboxes ,
  &.form-radios {
  }
}


/*
 * Highlight marks and form required mark
 */
.marker,
.form-required {
  color: $highlight;
}


/*
 * The submit button
 */
.form-submit {
}


.container-inline {
  div,
  label {
    display: inline;
  }
}

/*
 * Define consistent borders
 */
fieldset {
  border: 1px solid $border;
}

/*
 * Tips for Drupal's input formats
 */
.tips {
}


/*
 * Buttons used by contrib modules like Media
 */
a.button {
//  @include appearance(button);
}


/*
 * Password confirmation
 */
.password-parent,
.confirm-parent {
  margin: 0;
}


/* =============================================================================
 *   Tables
 * ========================================================================== */

table {
  margin: 0 0 20px 0;
  padding: 0;
  width: 100%;

  &.sticky-header {
    z-index: 10;
  }
}

tbody {
  border-top: none;
}

table,
thead,
tbody,
tr,
th,
td {

}


table,
td,
th {
  vertical-align: middle;
}


caption,
th,
td {
  text-align: left;
}


thead tr {
  font-weight: 700;
}


td,
th {
  border-bottom: 0;
  margin: 0;
  padding: 0;
}


tbody {}


tbody tr {

}


tr {

  &.odd {
    background: $page;
  }

  /* Table row striping */
  &.info,
  &.even,
  &:nth-child(2n+2)  {
    border-bottom: 0;
    background-color: $ultralight;
  }

  &.drag {}

  &.drag-previous {}

  &.odd td.active {
    background-color: $light;
  }

  &.even td.active {
    background-color: $medium_light;
  }
}


.lt-ie8 tr {

  &.even,
  &.odd {

    th,
    td {
    }
  }
}


/*
 * Forum tables
 * Core sets white-space to nowrap, which makes no sense
 */
#forum td {
  &.created,
  &.posts,
  &.topics,
  &.last-reply,
  &.replies,
  &.pager {
    white-space: normal;
  }
}


/* =============================================================================
 *   Messages
 * ========================================================================== */

div {
  &.messages {
  // Left and right margin are set by the global gutter width
  margin-bottom: 10px;
  margin-top: 10px;

    ul {
      margin-top: 0;
      margin-bottom: 0;
    }
  }

  &.status {
  }

  &.warning {
  }
}

tr.warning {
}


div.error,
tr.error {
}


.error {
}


.warning {
}


/*
 * Unpublished nodes
 */
.node-unpublished,
.comment-unpublished {
}


.node-unpublished,
.comment-unpublished {

  p.unpublished {
    @include unpublished;
  }
}


/* =============================================================================
 *    Maintenance pages
 * ========================================================================== */

.maintenance-page {
  .container {
    padding: 40px 0;
  }

  #site-name,
  #page-title {
    margin: 0;
  }
}


.db-offline {
  .container {
    margin: 0 auto;
    padding: 40px 0;
    width: 100%;
    max-width: 960px;
  }

  div.messages {
    margin: 20px 0 0;
  }

  #content {
    padding: 20px 0;
  }
}


/* =============================================================================
 *   Misc overrides for contrib modules
 * ========================================================================== */

/*
 * Admin menu overrides
 */
#admin-menu {
  margin: 0;
  padding: 0;
}


/*
 * Devel Module
 */
.dev-query {
  background: $light;
  padding: 30px;
}


/*
 * Styleguide module tweaks
 */
#styleguide-header {
  padding: 0 10px;

  .item-list {
    font-family: inherit;
    margin: 0 20px 20px 0;
    min-height: 260px;
    width: auto;
  }
}


// the left side bar
.sidebar {
  .box,
  #block-block-10,
  #block-block-15,
  #block-gob-display-free-games,
  #block-simplenews-157,
  #block-easy-social-easy-social-block-1 {
    background-color: white;
    border: 1px solid #e9dcc4;
    border-radius: 4px;
    box-shadow: 0 0 4px #e2dac8;
    margin-bottom: 20px;
    width: 270px;

    .box_head {
        background: url("/sites/all/themes/gobzor/css/images/box_head.jpg") repeat-x scroll 0 0 rgba(0, 0, 0, 0);
        font-size: 1.5em;
        height: 25px;
        padding: 9px 20px;
      a {
        color: #252525;
      }
    }
    .box_content {
      padding-bottom: 45px;
    }

  }

  // Эксклюзивные предложения
  #block-block-15 {
    .box_head {
      padding-top: 14px;
      font-size: 1.1em !important;
      color: #e70509;
      font-weight: bold;
    }
    .content {
    }
  }

  // Simple block with text - GamblingObzor.com не принимает оплату .....
  #block-block-10 {
    p {
        padding: 15px 15px 0;
    }
  }

  // Бесплатные игры на нашем сайте
  #block-gob-display-free-games {
    .box_head a {
      color: #e70509;
      text-decoration: none;
      font-size: 0.7em;
      font-weight: bold;
    }
    span {
      display: inline-block;
      margin: 3px;
      text-align: center;
    }
  }

  // Лучшие акции и бонусы на email
  #block-simplenews-157 {
    .box_head {
      padding-top: 14px;
      color: #e70509;
      font-size: 1.1em;
      font-weight: bold;
    }
    .content {
      padding: 7px;
      .form-item-mail {
        margin-bottom: 3px;
        margin-top: 3px;
        display: inline-block;
      }
      p {
        margin-bottom: 0;
      }
    }
  }
  .fb-like-box {
    padding-bottom: 18px;
  }
}

//
.box {
  border: 1px solid #e9dcc4;
  border-radius: 4px;
  margin-bottom: 20px;

  .box_head {
    @extend .box_head;
  }
  .box_content {
    .view {
      padding: 15px;
      .view_section_bg {
        background-color: white;
        border-radius: 4px;
        box-shadow: 0 0 3px #e2dac8;
        margin: 0 0 15px 0;
        padding: 10px;
        img {
          border: 1px solid white;
          box-shadow: 0 0 4px #e2dac8;
          margin-right: 0 !important;
        }
        .img {
          text-align: center;
          width: 134px;
        }
        .description {
          padding-left: 20px;
        }
      }
    }
    .box_item {
      clear: both;
      padding: 5px 20px;
      img {
        float: left;
        margin-bottom: 5px;
        margin-right: 25px;
      }
    }
    .review {
        float: left;
        padding-left: 20px;
    }
  }

  table, tbody, tr, td {
    border: 0 none;
    padding: 0;
  }

  tr.odd, tr.even {
    background: none repeat scroll 0 0 rgba(0, 0, 0, 0);
    border: 0 none;
    padding: 0;
  }

  table {
    margin: 0;
  }
}

// used not only in .box but also in .front_news here /loterei
.box_head {
  background: url("/sites/all/themes/gobzor/css/images/box_head.jpg") repeat-x scroll 0 0 rgba(0, 0, 0, 0);
  font-size: 1.6em;
  height: 25px;
  padding: 9px 20px;
  line-height: 1.2;
  a {
    color: #252525;
  }
  .box_head_link a {
    font-size: 0.8em;
    padding-left: 10%;
    color: #3785d7;
  }
}
.view_section_bg table {
  margin: 0;
}

//
#block-views-rating-block-14 .box_head {
  font-size: 1em;
  font-weight: bold;
}

// footer of the page
.footer-two-columns {
  border-radius: 4px;
  background: url("/sites/all/themes/gobzor/css/images/wrapper_bg.jpg") repeat scroll 0 0 transparent;
  margin-top: 25px;
  padding: 10px;
}

// header of the page
header {
  #branding {
    display: inline;
    float: left;
    width: 30%;
    margin-left: 0;
    margin-right: 0;
  }
  #top-banner {
    display: inline;
    float: left;
    padding-top: 0;
    width: 70%;
  }
}

// the flash games page
.flash_games_view {
  margin: 0 auto;
  text-align: center;
  .head_flash {
    clear: both;
    color: #424242;
    font-size: 16px;
    font-weight: bold;
    text-align: center;
  }
  ul {
    display: inline-block;
    text-align: center;
    padding-left: 0;
    li {
      float: left;
      height: 135px;
      list-style: outside none none;
      margin: 0;
      padding: 10px;
      text-align: center;
      width: 160px;
      a {
        display: inline-block;
        font-size: 14px;
        img {
          box-shadow: 0 0 3px #252525;
          margin-right: 0 !important;
        }
      }
      &.casino-play-cell {
        height: 200px;
        .casino-play-cell-title {
            height: 30px;
        }
        .play_button {
          margin-top: 11px;
          a {
            background: url("/sites/all/themes/gam/img_pages/play_button.png") no-repeat scroll 0 0 rgba(0, 0, 0, 0);
            display: block;
            height: 32px;
            margin: 0 auto;
            text-decoration: none;
            width: 85px;
          }
        }
      }
    }
  }
}

// secondary menu
#block-go-menu-go-secondary-menu {
  margin-left: 0;
  margin-right: 0;
  ul {
    background: linear-gradient(to bottom, #f7efe2  0%, #f7efe2  0%, #e7dfcc  100%, #e7dfcc  100%) repeat scroll 0 0 rgba(0, 0, 0, 0);
    border-radius: 4px;
    overflow: hidden;
    padding: 7px 20px 7px;
    a {
      color: #1d5216;
      &.active {
        color: #252525;
      }
    }
  }
}

// block easy-social
#block-easy-social-easy-social-block-1 .easy_social_box div.easy_social-widget {
    display: inline-block;
    float: none;
}
#block-easy-social-easy-social-block-1 {
    background-color: white;
    border: 1px solid #e9dcc4;
    border-radius: 4px;
    box-shadow: 0 0 4px #e2dac8;
    margin-bottom: 20px;
    width: 270px;
  .content {
    background-color: #fefcf7;
    margin-left: 20px;
    .easy_social-widget-facebook {
      padding-left: 10px;
      padding-top: 3px;
      position: absolute;
      a {
        display: block;
        padding-bottom: 12px;
      }
    }
    .easy_social-widget-vkontakte_share {
      padding-left: 7px;
      padding-top: 3px;
      position: absolute;
      a {
        display: block;
        padding-bottom: 12px;
      }
    }
    .easy_social-widget-googleplus {
      padding-left: 10px;
      padding-top: 3px;
      position: absolute;
      a {
        display: block;
        padding-bottom: 12px;
      }
    }
    .easy_social-widget-twitter_follow {
      padding-left: 10px;
      padding-top: 3px;
      position: absolute;
      a {
        display: block;
        padding-bottom: 12px;
      }
    }
  }

}

//
.view_section_bg {
  background-color: white;
  border-radius: 4px;
  box-shadow: 0 0 3px #e2dac8;
  margin: 0 2px 15px 0;
  padding: 10px 10px 10px 0;
  .view_section_title {
    font-size: 1.5em;
    padding-bottom: 10px;
    a {
      color: #252525;
    }
  }
  td.img,
  td.view_section_td_img {
    width: 20%;
    text-align: center;
    img {
      border: 1px solid white;
      border-radius: 7px;
      box-shadow: 0 0 4px #e2dac8;
    }
  }
  td.description,
  td.view_section_td_body {
    width: 80%;
    span.gray,
    span.gray a {
        color: #9e9e9e;
    }
  }
}

//
td.preview_links a {
    margin-left: 40px;
}
tr.odd, tr.even {
    background: none repeat scroll 0 0 rgba(0, 0, 0, 0);
    border: 0 none;
    padding: 0;
}

//
#content img {
  border-radius: 4px;
  margin-right: 15px;
}

// Buttons
#casino_bottom_button {
    margin: 30px auto;
    text-align: center;
}
.flash_version {
    border: 1px solid #d62f2f;
    border-radius: 6px;
    box-shadow: 0 1px 0 0 #ffffff inset;
    color: #ffffe5;
    display: inline-block;
    font-family: arial;
    font-size: 24px;
    font-weight: bold;
    padding: 6px 24px;
    text-decoration: none;
    text-shadow: 1px 1px 0 #292310;
//    background: -moz-linear-gradient(top , #ff6e7d 5%, #cf1313 100%) repeat scroll 0 0 #ff6e7d;
//  @include background( linear-gradient(to bottom right, #ff6e7d, #cf1313));
    background: linear-gradient(to bottom right, #ff6e7d, #cf1313);
}
.down_game {
    border: 1px solid #023300;
    border-radius: 6px;
    box-shadow: 0 1px 0 0 #ffffff inset;
    color: #d2fcca;
    display: inline-block;
    font-family: arial;
    font-size: 24px;
    font-weight: bold;
    padding: 6px 24px;
    text-decoration: none;
    text-shadow: 1px 1px 0 #292310;
//    background: -moz-linear-gradient(top , #08bd02 5%, #26730f 100%) repeat scroll 0 0 #08bd02;
//  @include background( linear-gradient(to bottom right, #08bd02, #26730f));
    background:  linear-gradient(to bottom right, #08bd02, #26730f);
}

// centering ads in a sidebar
#block-views-advertisement-blocks-block-16,
#block-views-advertisement-blocks-block-17 {
  .simpleads-wrapper {
    text-align: center;
    margin-left: auto;
    margin-right: auto;
    .simpleads-item {
      text-align: center;
      margin-left: auto;
      margin-right: auto;
    }
  }
}

// shared42 buttons
#block-share42-share42 {
  .box_head {
    background: none;
    font-size: 1em;
    padding: 15px 0 0 0;
    text-align: center;
  }
  .share42init {
    text-align: center;
    padding-bottom: 20px;
    #share42 {
      border: none;
      background: none;
      a {
        opacity: 1;
      }
      &:hover {
        background: none;
        box-shadow: none;
      }
    }
  }
}

// Mobile menu
.mean-container {
  .mean-bar {
    float: unset;
    a.meanmenu-reveal {
      width: auto;
      text-indent: 0;
      font-size: 1.4em;
      padding-left: 0;
      padding-right: 0;
      text-align: center;
    }
    ul {
      li {
        background-color: #0c1923;
        a.mean-expand {
          padding-top: 6px !important;
          padding-bottom: 3px !important;
        }
      }
    }
  }
}

// forum
#forum {
  line-height: 1.7em;
  td {
    padding: 2px 3px;
  }
  .forum-table-topics td {
      padding: 10px 5px;
  }
  .forum-name,
  .forum-topic-title {
      font-weight: bold;
  }
  .forum-subforums-label {
      display: block;
  }
}

.forum-login-links {
  float: right;
  margin-bottom: 10px;
  width: 30%;
  padidng-top: 10px;
}
